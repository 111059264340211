/** @jsx jsx */

import { FC } from 'react'
import { Flex, jsx } from 'theme-ui'

interface LogoEntryProps {
  logos: {
    src: string
    bg: string
  }[][]
  title: string
  description: string
}

const LogoEntry: FC<LogoEntryProps> = ({ logos, title, description }) => {
  return (
    <div sx={{ mb: 4, p: 4, bg: `background` }}>
      <div>
        <h2 sx={{ my: 0, [`a:hover &, a:focus &`]: { textDecoration: `underline` } }}>{title}</h2>
        <p>{description}</p>
      </div>

      <div sx={{ overflowX: `auto` }}>
        {logos.map((logo, key) => {
          return (
            <Flex {...{ key }} sx={{ maxWidth: `100%` }}>
              {logo.map(({ src, bg }, index) => {
                return (
                  <img
                    key={src}
                    alt=""
                    {...{ src }}
                    sx={{ boxSizing: `content-box`, height: [`28px`, `64px`], mt: 1, ml: index ? 1 : 0, p: 4, bg }}
                  />
                )
              })}
            </Flex>
          )
        })}
      </div>
    </div>
  )
}

export default LogoEntry
