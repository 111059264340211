/** @jsx jsx */

import { FC } from 'react'
import { jsx } from 'theme-ui'

import Halo from '../components/halo'
import Intro from '../components/intro'
import Layout from '../components/layout'
import { content, halo, intro } from '../content/brand'

const Brand: FC = () => {
  return (
    <Layout includeVisual={false}>
      <Halo {...halo} />
      <Intro {...intro} />
      <section sx={{ bg: `gray1_slate8` }}>{content}</section>
    </Layout>
  )
}

export default Brand
