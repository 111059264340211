/** @jsx jsx */

import { Fragment } from 'react'
import { Flex, jsx } from 'theme-ui'

import logomarkClearSpace from '../assets/brand/cartax-logomark-clear-space.png'
import logotypeClearSpace from '../assets/brand/cartax-logotype-clear-space.png'
import logotypeMisuse1 from '../assets/brand/cartax-logotype-misuse-1.png'
import logotypeMisuse2 from '../assets/brand/cartax-logotype-misuse-2.png'
import logotypeMisuse3 from '../assets/brand/cartax-logotype-misuse-3.png'
import logotypeMisuse4 from '../assets/brand/cartax-logotype-misuse-4.png'
import logomarkFullColorDark from '../assets/logos/full-color/cartax-logomark-full-color-dark.svg'
import logomarkFullColorLight from '../assets/logos/full-color/cartax-logomark-full-color-light.svg'
import logotypeFullColorDark from '../assets/logos/full-color/cartax-logotype-full-color-dark.svg'
import logotypeFullColorLight from '../assets/logos/full-color/cartax-logotype-full-color-light.svg'
import logomarkOneColorDark from '../assets/logos/one-color/cartax-logomark-one-color-dark.svg'
import logomarkOneColorLight from '../assets/logos/one-color/cartax-logomark-one-color-light.svg'
import logotypeOneColorDark from '../assets/logos/one-color/cartax-logotype-one-color-dark.svg'
import logotypeOneColorLight from '../assets/logos/one-color/cartax-logotype-one-color-light.svg'
import logomarkTwoColorDark from '../assets/logos/two-color/cartax-logomark-two-color-dark.svg'
import logomarkTwoColorLight from '../assets/logos/two-color/cartax-logomark-two-color-light.svg'
import logotypeTwoColorDark from '../assets/logos/two-color/cartax-logotype-two-color-dark.svg'
import logotypeTwoColorLight from '../assets/logos/two-color/cartax-logotype-two-color-light.svg'
import Button from '../components/button'
import LogoEntry from '../components/logo-entry'
import routes from '../constants/routes'

export const halo = {
  title: `Brand guidelines`,
  route: routes.brand,
  description: `The following is a set of resources and guidelines meant to help communicate the CartaX brand.`,
}

export const intro = {
  ...halo,
  secondaryDescription: (
    <Fragment>
      <small sx={{ mb: 3, display: `block`, fontSize: 0, color: `gray5_slate4` }}>
        Use of CartaX logo and word marks for commercial purposes without the prior written consent of CartaX is
        prohibited. CartaX does not permit or consent to any use of CartaX’s name, logo or word marks in any manner that
        is likely to cause confusion or imply association with or sponsorship by CartaX. Please consult your legal
        counsel for advice. All materials that use CartaX’s name, logo and word marks must adhere to the CartaX branding
        guidelines below.
      </small>
      <Button as="a" href={routes.logoKit} download sx={{ mt: 2 }}>
        Download the CartaX logo kit
      </Button>
    </Fragment>
  ),
}

const logoEntries = [
  {
    logos: [
      [
        { src: logomarkFullColorDark, bg: `white` },
        { src: logotypeFullColorDark, bg: `white` },
      ],
      [
        { src: logomarkFullColorLight, bg: `black` },
        { src: logotypeFullColorLight, bg: `black` },
      ],
    ],
    title: `Full color`,
    description: `The full color logos are meant to be used primarily for marketing materials, large prints, iOS and Android app icons, and high-resolution screens.`,
  },
  {
    logos: [
      [
        { src: logomarkTwoColorDark, bg: `white` },
        { src: logotypeTwoColorDark, bg: `white` },
      ],
      [
        { src: logomarkTwoColorLight, bg: `black` },
        { src: logotypeTwoColorLight, bg: `black` },
      ],
    ],
    title: `Two color`,
    description: `The two color logos are meant to be used for merchandise, fabric stitching, stickers, favicon, on-screen logos, letterhead, business cards, and other branded printed materials.`,
  },
  {
    logos: [
      [
        { src: logomarkOneColorDark, bg: `white` },
        { src: logotypeOneColorDark, bg: `white` },
      ],
      [
        { src: logomarkOneColorLight, bg: `black` },
        { src: logotypeOneColorLight, bg: `black` },
      ],
    ],
    title: `One color`,
    description: `The one color logos are meant to be used primarily for packaging, stamping, faxing, and single color paper prints.`,
  },
]

export const content = (
  <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, pt: 4, pb: 5 }}>
    <div sx={{ mb: 4, p: 4, bg: `background` }}>
      <h1 sx={{ mt: 0 }}>Logos</h1>
      <p>CartaX has two logos.</p>
      <ul sx={{ fontSize: 3 }}>
        <li>The "X" logomark</li>
        <li>The "CartaX" logotype</li>
      </ul>
      <p sx={{ mb: 0 }}>
        There are three versions of these logos: <i>full color</i>, <i>two color</i>, and <i>one color</i>.
      </p>
    </div>

    {logoEntries.map((props, key) => {
      return <LogoEntry {...{ key, ...props }} />
    })}

    <div sx={{ mb: 4, p: 4, bg: `background` }}>
      <h2 sx={{ mt: 0 }}>Rules</h2>
      <ul>
        <li>
          As defined in the Carta brand guidelines, the Carta logotype must always be displayed in white
          <code sx={{ mx: 1, border: `1px solid`, py: 1, px: 2, color: `black`, bg: `white` }}>#ffffff</code>, black
          <code sx={{ mx: 1, py: 1, px: 2, color: `white`, bg: `black` }}>#000000</code>, or dark blue
          <code sx={{ mx: 1, py: 1, px: 2, color: `white`, bg: `blue7` }}>#25314c</code>.
        </li>
        <li>The "{`>`}" in the "X" mark in the CartaX logotype should always match the color of the Carta logotype.</li>
        <li>
          The "{`<`}" portion of the "X" mark, should be set in blue{` `}
          <code sx={{ mx: 1, py: 1, px: 2, color: `black`, bg: `blue3` }}>#02a1fe</code>.
        </li>
      </ul>
    </div>

    <div sx={{ mb: 4, p: 4, bg: `background` }}>
      <h2 sx={{ mt: 0 }}>Clear space</h2>
      <p>
        Use the width of a leg of the "X" to determine the minimum amount of space required around the CartaX logomark.
      </p>
      <img
        src={logomarkClearSpace}
        alt="CartaX logotype clear space"
        sx={{ maxWidth: `100%`, width: 240, display: `block` }}
      />
      <p>Use the height of the "X" to determine the minimum amount of space required around the CartaX logotype.</p>
      <img
        src={logotypeClearSpace}
        alt="CartaX logomark clear space"
        sx={{ maxWidth: `100%`, width: 320, display: `block` }}
      />
    </div>

    <div sx={{ mb: 4, p: 4, bg: `background` }}>
      <h2 sx={{ mt: 0 }}>Misuse</h2>
      <p>Use only the logos supplied directly by CartaX. Never change the logo colors or modify the logo in any way.</p>
      <Flex sx={{ flexWrap: `wrap` }}>
        <img
          src={logotypeMisuse1}
          alt="CartaX logotype misuse example #1"
          sx={{ maxWidth: [`100%`, 320], maxHeight: 155 }}
        />
        <img
          src={logotypeMisuse4}
          alt="CartaX logotype misuse example #2"
          sx={{ maxWidth: [`100%`, 320], maxHeight: 155 }}
        />
        <img
          src={logotypeMisuse3}
          alt="CartaX logotype misuse example #3"
          sx={{ maxWidth: [`100%`, 320], maxHeight: 155 }}
        />
        <img
          src={logotypeMisuse2}
          alt="CartaX logotype misuse example #4"
          sx={{ maxWidth: [`100%`, 320], maxHeight: 155 }}
        />
      </Flex>
    </div>

    <div sx={{ mx: 4, mb: 4 }}>
      <Button as="a" href={routes.logoKit} download>
        Download the CartaX logo kit
      </Button>
    </div>

    <div sx={{ p: 4, bg: `background` }}>
      <h1 sx={{ mt: 0 }}>Referencing CartaX</h1>
      <p>Always write out "CartaX" with a capital "X" and no spaces.</p>
      <ul sx={{ mb: 0, fontSize: 3 }}>
        <li>CartaX</li>
      </ul>
      <p>
        When referring to the legal entity, write out "Carta Capital Markets, LLC" and when abbreviating, use "CCMX".
      </p>
      <ul sx={{ mb: 0, fontSize: 3 }}>
        <li>Carta Capital Markets, LLC</li>
        <li>CCMX</li>
      </ul>
    </div>
  </div>
)
